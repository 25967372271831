export function getFormattedDate(date, delimeter = '-') {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return (
        year +
        delimeter +
        (month >= 10 ? month : '0' + String(month)) +
        delimeter +
        (day >= 10 ? day : '0' + String(day))
    );
}

export function getYearMonthDay(date) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return { year, month, day };
}

export function getYearMonthDayInFullDigit(date) {
    const { year, month, day } = getYearMonthDay(date);
    const yearInFullDigit = year;
    const monthInFullDigit = month >= 10 ? month : '0' + String(month);
    const dayInFullDigit = day >= 10 ? day : '0' + String(day);
    return { yearInFullDigit, monthInFullDigit, dayInFullDigit };
}

export const getFormattedToday = (t) => {
    if (t != null) {
        return getFormattedDate(t);
    }else{
        const today = new Date();
        return getFormattedDate(today);
    }
}

export const getDayOfWeek = (t) => { //ex) getDayOfWeek('2022-06-13')
    const week = ['일', '월', '화', '수', '목', '금', '토'];
    const dayOfWeek = week[new Date(t).getDay()];

    return dayOfWeek;

}

export const getToday = () => {
    return new Date();
}


export const getDateList = (d=new Date(), days=9) =>{
    let ret = [];
    // add days to date
    for(let i=0; i<days; i++){
        let date = new Date(d);
        date.setDate(date.getDate() + i);
        ret.push(date);
    }
    return ret;
}