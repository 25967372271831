import React from 'react';
import styled from 'styled-components';
import LogoComponent from './LogoComponent';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
`;

// const TableLinkWrapper = styled.div`
//   position: sticky;
//     box-sizing: border-box;
//     width: 100%;
// `;

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
`;

const Title = styled.div`
  box-sizing: border-box;
  width: 100%;
  font-weight: bold;
  text-align: center;
`;

const LogoWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const TitleComponent = (props) => {
    return (
        <Wrapper>
            <TitleWrapper>
                <LogoWrapper>
                    <LogoComponent/>
                </LogoWrapper>
                <Title>전국 시군구 농작물 생육단계·고온해·저온해 예보</Title>
            </TitleWrapper>
        </Wrapper>
    );
}

export default TitleComponent;
