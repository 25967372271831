import React from 'react';
import styled from "styled-components";

import loadingImage from "../image/spinning-ball.gif";

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 10px;
`

const Img = styled.img`
    width: 100px;
`

const LoadingComponent = (props) => {
    const  {style} = props;
    return (
        <Wrapper
            style={
                style? style : {}
            }
        >
            <Img src={loadingImage} alt="로딩이미지"/>
        </Wrapper>
    );
};

export default LoadingComponent;