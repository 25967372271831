import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Link, useLocation} from "react-router-dom";


const LinkBox = styled.div`
  display: block;
  position: fixed;
  top: 1.2em;
  left: 1.6em;
  font-size: 0.6em;
`;

const LinkText = {
    '/': '시군카운트',
    '/siguncount': '전국시군구예보',
}

const LinkDict = {
    '/': '/siguncount',
    '/siguncount': '/',
}

const TableLinkComponent = () => {
    const location = useLocation();
    const [text, setText] = useState(LinkText[location.pathname]);
    const [link, setLink] = useState(LinkDict[location.pathname]);

    useEffect(() => {
        if(location) setText(LinkText[location.pathname]);
        if(location) setLink(LinkDict[location.pathname]);
    }, [location]);

    return (
        <LinkBox>
            <Link to={link}>
                {text}
            </Link>
        </LinkBox>
    )

}

export default TableLinkComponent;