import React, {Fragment, useContext, } from "react";
import styled from 'styled-components';

import SigunCountSelectorOrganism from "../components/SigunCountSelectorOrganism";
import JsonDataContainer from "../components/JsonDataContainer";
import MapImageContainer from "../components/MapImageContainer";
import {SelectedOptionsContext} from "../SelectedOptionsContext";
import LoadingComponent from "../components/LoadingComponent";

const SecondRow = styled.div`
    display: grid;
    grid-template-columns: 55% 45%;
    width: 100%;
`;

const SigunCountPage = (props) => {
    const { isLoading, } = useContext(SelectedOptionsContext);

    return (
        <Fragment>
            <SigunCountSelectorOrganism />
            <SecondRow>
                <JsonDataContainer />
                {
                    isLoading? <LoadingComponent /> : <MapImageContainer />
                }
            </SecondRow>
        </Fragment>
    )
}

export default SigunCountPage;
