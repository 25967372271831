import React, { useContext } from 'react';
import styled from 'styled-components';
import DatePicker, { registerLocale } from 'react-datepicker';
import ko from 'date-fns/locale/ko';
import 'react-datepicker/dist/react-datepicker.css';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { SelectedOptionsContext } from '../SelectedOptionsContext';
import './MyCustomedDatePicker.css';
import * as utils from '../utils/DateUtils';

registerLocale('ko', ko);

const Wrapper = styled.div`
    box-sizing: border-box;
    height: 1.7rem;
    width: 100%;
    padding: 0.2rem;
    margin: 0;
    border: solid 0.1rem #94d3ac;
    border-radius: 0.5rem;
    background-color: #effcef;
    color: #655c56;
    &:hover {
      background-color: #94d3ac;
      transition: 0.5s ease;
    }
    &:focus {
      outline: none;
    }
  `;
function SelectDateComponent(props) {
  const { selectedDate, onChangeDate } = useContext(SelectedOptionsContext);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const today = new Date();

  const today_date = utils.getFormattedDate(today, '/');

  const isValidDate = (date) => {
    const today = new Date();
    const baseDate = new Date('2024-01-01 00:00:00')
    if(date <= today && date >= baseDate) {
      return true;
    }
    return false;
  };

  return (
    <>
      <Wrapper>
        <DatePicker
          locale="ko"
          dateFormat="yyyy-MM-dd"
          selected={selectedDate}
          onChange={(e) => {
            if (isValidDate(e)) {
              onChangeDate(e);
            } else {
              handleClickOpen();
            }
          }}
        />
      </Wrapper>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'🙊 발표시각을 올바르게 선택하여 주세요.'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            과거(2024/01/01)부터 오늘({today_date})까지의 날짜만 선택할 수 있습니다. 😉
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            확인
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default SelectDateComponent;
