import React from 'react';
import styled from 'styled-components';

import brandLogo from '../image/logo_smaller_no_text.png';

const LogoWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  box-sizing: border-box;
  padding-bottom: 0.5rem;
`;

const Logo = styled.img`
  height: 1rem;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  padding-right: 0.2rem;
`;

const LogoText = styled.span`
  color: #94d3ac;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1rem;
`;

function LogoComponent() {
  return (
    <LogoWrapper>
      <Logo src={brandLogo} />
      <LogoText>Micro Climate</LogoText>
    </LogoWrapper>
  );
}

export default LogoComponent;
