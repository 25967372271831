import React from 'react';
import styled from 'styled-components';

import SelectRiskComponent from "./SelectRiskComponent";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 1rem;
  margin-top: 2px;
  margin-bottom: 2px;
  font-size: 1rem;
  line-height: 1rem;
  background-color: rgba(148, 211, 172, 0.05);
  border: solid 0.2rem rgba(148, 211, 172, 0.5);
  border-radius: 0.5rem;
`;

const SingleContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 1.7rem;
  width: 32%;
`;

const ContainerTitle = styled.div`
  box-sizing: border-box;
  padding-bottom: 0.2rem;
  color: #655c56;
  font-weight: bold;
`;

const SelectorWrapper = styled.div`
  height: 100%;
  width: 100%;

  &:focus {
    outline: none;
  }
`;

const OptionSelectorOrganism = (props) => {
    return (
        <Wrapper>
            <SingleContainerWrapper>
                <ContainerTitle>위험유형선택</ContainerTitle>
                <SelectorWrapper>
                    <SelectRiskComponent/>
                </SelectorWrapper>
            </SingleContainerWrapper>
        </Wrapper>
    );
}

export default OptionSelectorOrganism;
