import React, {Fragment, useContext, useEffect, useState} from "react";
import {SelectedOptionsContext} from "../SelectedOptionsContext";
import {getDateList, getToday, getFormattedToday, getDayOfWeek} from "../utils/DateUtils";
import {generatePromises, risk_title_arr} from "../utils/EpinetApiUtils";
import {crops} from "../data/micro_climate_data.json";
import LoadingComponent from "./LoadingComponent";


const risk_title_style = [
    {
        width: '100%',
        // color: '#ffffff',
        // backgroundColor:'#FF0000',
        color: '#FF0000',
        fontWeight: 'bold',
    },
    {
        width: '100%',
        // color: '#662e05',
        // backgroundColor:'#FFBB00',
        color: '#FFBB00',
        fontWeight: 'bold',
    }, {
        width: '100%',
        // color: '#ffffff',
        // backgroundColor:'#1DDB16',
        color: '#1DDB16',
        fontWeight: 'bold',
    }, {
        width: '100%',
        // color: '#ffffff',
        // backgroundColor:'#1DDB16',
        color: '#655c56',
        fontWeight: 'bold',
    }
]

const basicHeaderData = [{
    "id": 0,
    "name_kor": "작목",
    "type": {
        "type_eng": "crop",
        "type_kor": "작목"
    }
}]
const makeHeaderData = (total_data) => {

    let ret_data = {
        data: total_data,
        header: []
    }

    total_data[0].header.forEach((x) => {
        ret_data.header.push(
            crops.filter((crop) => {
                if (crop.name === x) {
                    return crop;
                }
            })[0]
        )
    })
    return ret_data;
}

const JsonDataContainer = () => {
    const {selectedRisk, onSelectRiskImgUrl, isLoading, onIsLoading, } = useContext(SelectedOptionsContext);
    const [jsonData, setJsonData] = useState([]);
    const [headerData, setHeaderData] = useState(basicHeaderData);
    const [gridColumnCnt, setGridColumnCnt] = useState(0);
    const getJsonData = async () => {
        const today = getToday();

        let risk_nm = selectedRisk.name_eng;
        let plants = crops.filter((crop) => {
            if (crop.risks.length > 0) {
                return crop;
            }
        });

        let date_arr = getDateList(today, 10);

        let json_data = [];

        for (let i = 0; i < date_arr.length; i++) {
            await generatePromises(plants, risk_nm, date_arr[i]).then((rep) => {
                json_data.push(rep);
            })
        }

        // 날짜별 정렬
        json_data.sort((a, b) => {
            return a.date - b.date;
        });

        return json_data;
    }

    useEffect(() => {
        onIsLoading(true);
        getJsonData().then(makeHeaderData).then((data) => {
            setJsonData(data.data);
            setHeaderData(basicHeaderData.concat(data.header));
            setGridColumnCnt(data.header.length + 1);
            onIsLoading(false)
        });
    }, [selectedRisk])


    return (
        <div
            style={{
                display: 'grid',
                width: "inherit",
                height: '793.8px',
                gridTemplateColumns: `repeat(${gridColumnCnt}, 1fr)`,
                fontSize: "0.9rem",
                whiteSpace: "pre-wrap",
                justifyContent: "center",
                textAlign: "center",
                lineHeight: "1rem",
                backgroundColor: 'rgba(148,211,172,0.05)',
                border: 'solid 0.2rem rgba(148,211,172,0.5)',
                boxSizing: 'border-box',
                borderRadius: '0.5rem',
                fontFamily: "inherit",
                color: '#655c56',
                padding: "0.5rem",
                overflowY: 'auto',
            }}
        >
            {
                isLoading ?
                    <LoadingComponent
                        style={{
                            gridColumnStart: 1,
                            gridColumnEnd: gridColumnCnt + 1,
                        }}
                    /> :
                    (
                        headerData.map((x, i) => {
                            let nm = x.name_kor;
                            if (x.type.type_eng != "crop") {
                                nm = `${x.name_kor}\n(${x.type.type_kor})`
                            }
                            return (
                                <div key={i} style={
                                    {
                                        fontWeight: 'bold',
                                    }
                                }>
                                    {nm}
                                </div>
                            )
                        })
                    )
            }
            {
                isLoading ? null :
                    (
                        jsonData.map((x, i) => {
                            let pre_dt = getFormattedToday(x.date);
                            let pre_dow = getDayOfWeek(x.date);

                            return (
                                <Fragment key={i}>
                                    <div style={{ // 날짜
                                        display: 'flex',
                                        gridColumnStart: 1, gridColumnEnd: gridColumnCnt + 1,
                                        fontWeight: '500',
                                        backgroundColor: '#effcef',
                                        borderTop: 'solid 0.1rem #94d3ac',
                                        borderBottom: 'solid 0.1rem #94d3ac',
                                        padding: '2px',
                                        margin: '2px 0 2px 0',
                                    }}>
                                        {`${pre_dt} (${pre_dow})`}
                                    </div>
                                    {
                                        Object.keys(x.result).map((y, j) => {
                                            let val_arr = x.result[y];
                                            return (
                                                <Fragment key={`${i}_${j}`}>
                                                    <div style={risk_title_style[j]}>
                                                        {risk_title_arr[j]}
                                                    </div>
                                                    {
                                                        val_arr.map((z, k) => {
                                                            return (
                                                                <div
                                                                    key={`${i}_${j}_${k}`}
                                                                    style={{width: '100%'}}
                                                                    onClick={() => {
                                                                        if (z.val != '-') {
                                                                            onSelectRiskImgUrl(z.url);
                                                                        }

                                                                    }}
                                                                >
                                                                    {z.val}
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </Fragment>
                                            )
                                        })
                                    }
                                </Fragment>
                            )
                        })
                    )
            }
        </div>
    )
}
export default JsonDataContainer;
