import React, { useContext } from 'react';

import { SelectedOptionsContext } from '../SelectedOptionsContext';
import SelectComponent from './SelectComponent';
// import { risks } from '../data/micro_climate_data_arr';
import { risks } from "../data/micro_climate_data.json";

function SelectRiskComponent(props) {
  const { onSelectRisk, selectedRisk, onSelectRiskImgUrl } = useContext(SelectedOptionsContext);

  const onChangeRisk = (e) => {
    onSelectRisk(e.target.value);
    onSelectRiskImgUrl(null);
  };

  return (
    <SelectComponent onChange={onChangeRisk} optionData={risks} selectedOption={selectedRisk} />
  );
}

export default SelectRiskComponent;
