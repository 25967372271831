import TitleComponent from "../components/TitleComponent";
import OptionSelectorOrganism from "../components/OptionSelectorOragnism";
import MapDataContainer from "../components/MapDataContainer";
import React, {Fragment} from "react";

const HomePage = () => {
    return (
        <Fragment>
            <TitleComponent/>
            <OptionSelectorOrganism/>
            <MapDataContainer/>
        </Fragment>
    )
}
export default HomePage;
