/* eslint-disable no-unreachable */
import * as EpinetAPIs from '../data/epinet_api_url.json';
import * as DateUtils from '../utils/DateUtils';
import axios from "axios";
axios.defaults.withCredentials = true;

export const getOldMapImageUrl = (
    selectedDate,
    predictionTargetDate,
    selectedCrop,
    selectedMapType,
    backendAPI = EpinetAPIs.oldmcapi,
    imageType = 'png'
) => {
    let mapImageUrl = '';
    // 지도의 유형(mapType)이 [tmin, tmax, rain, wsx]인 경우에는 url에 작목명(crop)이 들어가지 않는다
    if (selectedCrop.is_crop) {
        mapImageUrl = `${backendAPI}/${DateUtils.getFormattedDate(selectedDate, '/')}/${
            selectedCrop.name
        }${selectedCrop.type ? `.${selectedCrop.type.type_eng}` : ''}/${selectedMapType.name}/${DateUtils.getFormattedDate(predictionTargetDate, '-')}.${
            selectedCrop.name
        }${selectedCrop.type ? `.${selectedCrop.type.type_eng}` : ''}.${
            selectedMapType.name
        }.created-at.${DateUtils.getFormattedDate(selectedDate, '-')}.${imageType}`;
    } else {
        mapImageUrl = `${backendAPI}/${DateUtils.getFormattedDate(selectedDate, '/')}/${
            selectedMapType.name
        }/${DateUtils.getFormattedDate(predictionTargetDate, '-')}.${
            selectedMapType.name
        }.created-at.${DateUtils.getFormattedDate(selectedDate, '-')}.${imageType}`;
    }
    return mapImageUrl
}

export const generateOldJsonUrl = (plant_eng, risk_eng, data_date, format, type='') =>{
    // 오늘 날짜
    let today_formatted = DateUtils.getFormattedToday(DateUtils.getToday());
    let today_year = today_formatted.split('-')[0];
    let today_month = today_formatted.split('-')[1];
    let today_day = today_formatted.split('-')[2];
    let data_date_formatted = DateUtils.getFormattedToday(data_date);

    let real_risk_eng = risk_eng;
    if(risk_eng.includes('_ori')){
        real_risk_eng = risk_eng.replace('_ori', '');
    }

    return `https://mc-api.agmet.kr/static/${today_year}/${today_month}/${today_day}/${plant_eng}/${real_risk_eng}/`+
        `${data_date_formatted}.${plant_eng}.${risk_eng}.created-at.${today_formatted}.${format}`
}

const generateOldPromise = async (plant_eng, risk_eng, data_date) => {
    return new Promise(async (resolve, reject) => {
        let ret_data={
            date: data_date,
            crop: plant_eng,
            risk: risk_eng,
            data: null
        }

        try {
            let url = generateOldJsonUrl(plant_eng, risk_eng, data_date, 'json');
            await axios.get(url, { withCredentials: false }).then((res) => {
                ret_data.data = res.data;
                resolve(ret_data)
            }).catch((err) => {
                resolve(ret_data)
            })
        } catch (e) {
            resolve(ret_data)
        }
    });
}

const risk_structure = [
    {
        id: 0,
        value: 3,
        title: '위험',
        sub_title: ['위험', '경고(120mm 이상)', '경고(14m/s 이상)'],
        data: []
    },
    {
        id: 1,
        value: 2,
        title: '주의',
        sub_title: ['주의', '주의(80mm 이상 120mm 미만)', '주의(11m/s 이상 14m/s 미만)'],
        data: []
    },
    {
        id: 2,
        value: 1,
        title: '관심',
        sub_title: ['관심', '관심(0mm 이상 80mm 미만)', '관심(0m/s 이상 11m/s 미만)'],
        data: []
    },
    {
        id: 3,
        value: 0,
        title: '해당 없음',
        sub_title: ['해당 없음', '해당없음'],
        data: []
    },
]

// ['관심', '주의', '위험']
export const risk_title_arr = risk_structure.map((x) => x.title).flat();

export const generateOldPromises = async (plant_eng_ary, risk_eng, data_date) => {
    let ret_data = {
        date: data_date,
        header: [],
        result: [[],[],[],[]],
    }
    return new Promise(async (resolve, reject) => {
        try {
            let promises = plant_eng_ary.map(async (x) => {
                let plant_full_nm = `${x.name_eng}.${x.type.type_eng}`;
                ret_data.header.push(plant_full_nm);
                return await generateOldPromise(plant_full_nm, risk_eng, data_date);
            });

            let pro_data  = await Promise.all(promises)

            let risk_sub_title_index = null;

            if(risk_eng==='rr'){
                risk_sub_title_index = 1;
            }else if(risk_eng==='wsxr'){
                risk_sub_title_index = 2;
            }

            for(let i = 0; i < pro_data.length; i++){
                let d = pro_data[i];

                for(let j = 0; j < risk_title_arr.length; j++){
                    let key = risk_title_arr[j]; // '관심', '주의', '위험'
                    let ori_key = key;

                    if(d.data != null && risk_sub_title_index != null){
                        key = risk_structure.find((x) => x.title === key).sub_title[risk_sub_title_index];
                    }

                    let row_data = {
                        crop: d.crop,
                        risk: d.risk,
                        val:  d.data === null? '-' : d.data[key],
                        url: generateOldJsonUrl(d.crop, d.risk+'_ori', d.date, 'png'), //범례 없는 이미지를 위함
                    }

                    let idx = risk_structure.find((x) => x.title === ori_key).id;
                    ret_data.result[idx].push(row_data)
                }
            }
            resolve(ret_data);
        } catch (e) {
            reject(e);
        }
    });
}
